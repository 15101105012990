$mic: 800px;
$mediu: 900px;

@mixin position($sticky) {
    -webkit-position: $sticky;
    -moz-position: $sticky;
    -ms-position: $sticky;
     -o-position: $sticky;
        position: $sticky;
  }
  
  $sticky: sticky;
  
  .sticky{
    z-index: 12;
    @include position($sticky);
    top: 70px;
  }

// .sticky {
//     position: -webkit-sticky;
//     position: sticky;
//     top: 10vh;
//     // @media screen and (max-width: $mic) {
//     //     top: 10vh;
//     //   }
//     // @media screen and (min-width: $mediu) {
//     //     top: 10vh;
//     // }
//   }