body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

h1,h2,h3,.h1,.h2,.h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

h4,h5,h6,.h4,.h5,.h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

html *{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
    font-size: $h1-font-size;
    line-height: 1.15em;
}
h2, .h2 {
    font-size: $h2-font-size;
    line-height: 1.5em;
}
h3, .h3 {
    font-size: $h3-font-size;
    line-height: 1.4em;
}
h4, .h4 {
    font-size: $h4-font-size;
    line-height: 1.5em;
}
h5, .h5 {
    font-size: $h5-font-size;
    line-height: 1.55em;
    margin-bottom: 15px;
}
h6, .h6 {
    font-size: $h6-font-size;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
}
p {
    font-size: $font-paragraph;
    margin: 0 0 10px;
}

b {
    font-weight: 700;
}

small,
.small {
    font-size: 75%;
    color: #777;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading{
    font-weight: $font-weight-extra-bold;
    font-family: $font-family-serif;

    &,
    a{
        color: $black-color;
        text-decoration: none;
    }
}

h2.title{
    margin-bottom: $headings-margin-bottom * 2;
}

.description,
.card-description,
.footer-big p{
    color: $gray-light;
}

.text-warning {
  color: $warning !important;
}
.text-primary {
  color: $primary !important;
}
.text-danger {
  color: $danger !important;
}
.text-success {
  color: $success !important;
}
.text-info {
  color: $info !important;
}
.text-rose {
    color: $rose !important;
}
.text-gray {
    color: $gray-color !important;
}

.titlu-mare {
    background-image: url('../img/t3.jpg'); /* Change URL to texture file name */
    background-repeat: repeat;
    background-position: center center;
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.space {
    height: 130px;
    display: block;
}
.space-110 {
    height: 110px;
    display: block;
}
.space-50 {
    height: 50px;
    display: block;
}
.space-70 {
    height: 70px;
    display: block;
}

.blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;

    p {
        font-size: 1.063rem;
        font-style: italic;
    }

    small {
        display: block;
        font-size: 80%;
        line-height: 1.42857143;
        color: #777;
    }
}
